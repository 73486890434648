import { BffConfiguratorConfigurationColor, BffConfiguratorConfigurationColorItem } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Panel } from '@components/configurator';
import { FilterKey } from '@components/configurator/hooks';
import { useEffect, useState } from 'react';

import { LayerConfigureColorCompare } from './layer-configure-color-compare';
import { LayerConfigureColorOptionsDefault } from './layer-configure-color-default';
import { LayerConfigureColorOptions } from './layer-configure-color-options';
import { LayerConfigureColorSearch } from './layer-configure-color-search';

export interface LayerConfigureColorProps {
    configuration: BffConfiguratorConfigurationColor;
    filterCount: number;
    searchText: string;
    filteredColors: BffConfiguratorConfigurationColorItem[];
    disabledBackButton?: boolean;
    isCompareOpen: boolean;
    currencyCode: string;

    setIsCompareModalOpen: (value: boolean) => void;
    onToggleFilterLayerById: (filterLayerId?: string) => void;
    onChangeColor: (color: BffConfiguratorConfigurationColorItem) => void;
    onClose: () => void;
    onSetFilter: (key: FilterKey, value: string) => void;
    onClearFilter: () => void;
    colorIsSelected: (color: BffConfiguratorConfigurationColorItem) => boolean;
}

export const LayerConfigureColor = (props: LayerConfigureColorProps) => {
    const {
        configuration,
        searchText,
        filteredColors,
        isCompareOpen,
        currencyCode,
        colorIsSelected,
        onChangeColor,
        onSetFilter,
        onClearFilter,
        onToggleFilterLayerById,
        setIsCompareModalOpen
    } = props;

    const { colors } = configuration;
    const [mode, setMode] = useState<'default' | 'filter' | 'compare' | 'search'>('default');

    const { md } = useMedia();

    useEffect(() => {
        // Toggle on filter layer when mounting
        md && onToggleFilterLayerById(configuration.id);

        // Toggle off filter layer when unmounting
        return () => {
            md && onToggleFilterLayerById();
        };
    }, []);

    const handleReset = () => {
        setMode('default');
    };

    const handleComparePress = () => {
        setMode('compare');
    };

    const handleSearchPress = () => {
        setMode('search');
    };

    const handleSearchInputChange = (searchPattern: string) => {
        onSetFilter('search', searchPattern);
    };

    const handleClearSearch = () => {
        onSetFilter('search', '');
    };

    const options = (
        <LayerConfigureColorOptions
            configuration={configuration}
            filteredColors={filteredColors}
            onChangeColor={onChangeColor}
            onClearFilter={onClearFilter}
            colorIsSelected={colorIsSelected}
            currencyCode={currencyCode}
        />
    );

    const renderPanel = () => {
        const counter = (
            <Panel.Counter
                aria-label={`${colors?.length}`}
                filteredValue={filteredColors?.length || 0}
                totalValue={colors?.length || 0}
            />
        );

        switch (mode) {
            case 'compare':
                return (
                    <LayerConfigureColorCompare
                        onReset={handleReset}
                        onChangeColor={onChangeColor}
                        currencyCode={currencyCode}
                        filteredColors={filteredColors}
                        configuration={configuration}
                        isCompareOpen={isCompareOpen}
                        setIsCompareModalOpen={setIsCompareModalOpen}
                    />
                );
            case 'search':
                return (
                    <LayerConfigureColorSearch
                        searchText={searchText}
                        onClose={handleReset}
                        onClear={handleClearSearch}
                        onSearchInputChange={handleSearchInputChange}
                        counter={md && counter}
                    >
                        {options}
                    </LayerConfigureColorSearch>
                );
            default:
                return (
                    <LayerConfigureColorOptionsDefault
                        {...props}
                        onComparePress={handleComparePress}
                        onSearchPress={handleSearchPress}
                        counter={md && counter}
                    >
                        {options}
                    </LayerConfigureColorOptionsDefault>
                );
        }
    };

    return renderPanel();
};
