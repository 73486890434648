import { BffConfiguratorConfigurationColorItem, BffConfiguratorConfigurationColorMaterial } from '@common/graphql/sdk';
import { useElementLeavesViewport } from '@common/hooks/use-element-leaves-viewport';
import { useMedia } from '@common/hooks/use-media';
import { AddElement, ArrowRight } from '@common/icons';
import { ButtonIcon } from '@common/primitives';
import { styles as buttonStyles } from '@common/primitives/button/button';
import { colors as themeColors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { ROW_WIDTH } from '@components/material-comparison/constants';
import { AttributeKey } from '@components/material-comparison/material-comparison-types';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { useRef } from 'react';
import { Button as DefaultButton } from 'react-aria-components';

import { TableImage } from './table-image';

interface TableHeaderProps {
    colors: BffConfiguratorConfigurationColorItem[];
    children: React.ReactNode;
    className?: string;
    materials?: BffConfiguratorConfigurationColorMaterial[];
    showEmpty?: boolean;
    currentPage: number;
    pinnedRows: AttributeKey[];
    totalPages: number;
    isRemoveButtonShown?: boolean;
    onClose: () => void;
    onChangePage: (page: number) => void;
    onPreviousPage: () => void;
    onNextPage: () => void;
    onRemoveColor: (id: string) => void;
}

const styles = {
    tableHeader: css`
        position: sticky;
        top: -1px;
        background-color: ${themeColors.white};
        z-index: 1;
        padding-left: 14px;
        padding-right: 14px;
        display: block;

        ${media.md} {
            padding-left: 0;
            padding-right: 0;
        }
    `,
    tableHeaderRow: css`
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        gap: 18px;
        padding-top: 22px;

        ${media.md} {
            width: fit-content;
        }

        th {
            flex: 1;
        }
    `,
    tableHeaderSticky: css`
        box-shadow: 0 2px 4px 0 rgba(0 0 0 / 10%);
        padding-bottom: 14px;
    `,
    tableHeaderPinned: css`
        padding-bottom: 14px;

        tr:last-of-type {
            td {
                border-bottom: none;
            }
        }
    `,
    emptyImage: css`
        box-shadow: 0 3px 4px 0 rgba(0 0 0 / 8%);
        height: 108px;
        width: ${ROW_WIDTH}px;
        background-color: #f5f5f5;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    pagerButton: css`
        position: absolute;
        top: 54px;
    `,
    pagerButtonLeft: css`
        left: 36px;

        svg {
            transform: rotate(180deg);
        }
    `,
    pagerButtonRight: css`
        right: 36px;
    `
};

export const TableHead = ({
    colors,
    children,
    className,
    showEmpty,
    currentPage,
    materials,
    pinnedRows,
    totalPages,
    isRemoveButtonShown,
    onClose,
    onPreviousPage,
    onNextPage,
    onChangePage,
    onRemoveColor
}: TableHeaderProps) => {
    const { md } = useMedia();

    const headerRef = useRef(null);

    const leavesViewport = useElementLeavesViewport({
        ref: headerRef,
        rootElementSelector: md ? '.panel-surface' : '.comparison-modal',
        delay: 250
    });

    const hasPinnedRows = pinnedRows.length > 0;

    return (
        <thead
            ref={headerRef}
            className={cx(
                styles.tableHeader,
                ((leavesViewport && headerRef.current) || hasPinnedRows) && styles.tableHeaderSticky,
                hasPinnedRows && styles.tableHeaderPinned,
                className
            )}
        >
            <tr className={styles.tableHeaderRow}>
                {md && currentPage > 0 && (
                    <th scope="col" className={cx(styles.pagerButton, styles.pagerButtonLeft)}>
                        <ButtonIcon
                            variant="ghost"
                            aria-label={t`Previous`}
                            onPress={onPreviousPage}
                            icon={<ArrowRight />}
                        />
                    </th>
                )}
                {colors?.map(({ id, title, material, listingImage }) => {
                    const materialName = materials?.find((m) => m.id === material)?.name;
                    const imageTitle = `${title}, ${materialName}`;

                    return (
                        <th scope="col" key={id}>
                            <TableImage
                                isRemoveButtonShown={isRemoveButtonShown}
                                onRemove={() => {
                                    onRemoveColor(id);

                                    // Reset the page if we're on the last page
                                    if (currentPage === totalPages - 1) {
                                        onChangePage(0);
                                    }
                                }}
                                title={imageTitle}
                                src={listingImage?.src || ''}
                                shape="rectangle"
                            />
                        </th>
                    );
                })}

                {showEmpty && (
                    <th>
                        <DefaultButton onPress={onClose} className={cx(buttonStyles.reset, styles.emptyImage)}>
                            <AddElement color={themeColors.grey} />
                        </DefaultButton>
                    </th>
                )}
                {md && currentPage < totalPages - 1 && (
                    <th scope="col" className={cx(styles.pagerButton, styles.pagerButtonRight)}>
                        <ButtonIcon variant="ghost" aria-label={t`Next`} onPress={onNextPage} icon={<ArrowRight />} />
                    </th>
                )}
            </tr>
            {children}
        </thead>
    );
};
