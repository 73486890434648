import { BffConfiguratorConfigurationColor, BffConfiguratorConfigurationColorItem } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Check } from '@common/icons';
import { styles as checkboxStyles } from '@common/primitives/checkbox/checkbox';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { ButtonTile } from '@components/configurator/components/button-tile';
import { ButtonTileImageImage } from '@components/configurator/components/button-tile/button-tile-image';
import { useModularConfigurator } from '@components/configurator/hooks';
import { css, cx } from '@linaria/core';
import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { ListBox, ListBoxItem, Selection } from 'react-aria-components';

interface LayerConfigureColorOptionsComapreProps {
    configuration: BffConfiguratorConfigurationColor;
    filteredColors: BffConfiguratorConfigurationColorItem[];
    selectedKeys?: Selection;
    onSelectionChange?: (selected: Selection | undefined) => void;
}

const styles = {
    compareList: css`
        display: flex;
        gap: 18px;
        height: max-content;

        .tile-item {
            flex-direction: column;
        }

        ${media.md} {
            flex-direction: column;

            .tile-item {
                flex-direction: row;
            }
        }
    `,
    compareListItem: css`
        display: flex;
        outline: none;
        border-radius: 5px;

        &[data-focus-visible] {
            outline: 1.5px solid ${colors.coral2};
            outline-offset: 3px;
        }

        &[data-selected] {
            .checkbox {
                background-color: ${colors.coral2};

                svg {
                    color: ${colors.white};
                }
            }
        }
    `,
    compareListCheckboxContainer: css`
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 0;
    `
};

export const LayerConfigureColorOptionsCompare = ({
    configuration,
    filteredColors,
    onSelectionChange,
    selectedKeys
}: LayerConfigureColorOptionsComapreProps) => {
    const { materials } = configuration;

    const { md } = useMedia();

    const clearSecondaryLayer = useModularConfigurator((state) => state.clearSecondaryLayer);

    const tileDirection = md ? 'row' : 'column';
    const thumbnailSize: [number, number] = md ? [162, 118] : [140, 102];

    const getMaterialName = (materialKey: string) => materials?.find((mat) => mat.id === materialKey)?.name || '';

    // Callback passes a react-aria-components Selection set to the parent
    const handleSelectionChange = (selection: Selection) => {
        onSelectionChange?.(selection);
    };

    useEffect(() => {
        // Hide the filter panel
        clearSecondaryLayer();
    }, []);

    const options = filteredColors?.map((color) => {
        return (
            <ListBoxItem key={color.id} id={color.id} className={styles.compareListItem}>
                <ButtonTile.Surface direction={tileDirection}>
                    <ButtonTile.Item direction={tileDirection}>
                        <ButtonTile.Image
                            size={thumbnailSize}
                            direction={tileDirection}
                            image={color.listingImage as ButtonTileImageImage}
                        >
                            <div className={styles.compareListCheckboxContainer}>
                                <div className={cx(checkboxStyles.checkbox, 'checkbox')}>
                                    <Check className={checkboxStyles.svg} />
                                </div>
                            </div>
                        </ButtonTile.Image>
                        <ButtonTile.Text
                            direction={tileDirection}
                            size="large"
                            title={color.title}
                            subtitle={getMaterialName(color.material)}
                        />
                    </ButtonTile.Item>
                </ButtonTile.Surface>
            </ListBoxItem>
        );
    });

    // If the selection is longer than 3, disable the rest on mobile
    const disabledKeys =
        !md && selectedKeys && selectedKeys !== 'all' && selectedKeys?.size > 2
            ? filteredColors?.map((color) => color.id).filter((id) => ![...selectedKeys].includes(id))
            : undefined;

    const optionsElement = (
        <ListBox
            aria-label={t`Compare fabrics`}
            selectionMode="multiple"
            onSelectionChange={handleSelectionChange}
            className={styles.compareList}
            selectedKeys={selectedKeys}
            disabledKeys={disabledKeys}
        >
            {options}
        </ListBox>
    );

    return optionsElement;
};
