import { abortRunningOperation, client } from '@common/graphql/client';
import {
    BffConfiguratorConfiguration,
    BffConfiguratorConfigurationColor,
    BffConfiguratorConfigurationColorItem,
    BffConfiguratorDelivery,
    BffConfiguratorModular,
    BffConfiguratorModularCategories,
    BffConfiguratorModularPreset,
    BffConfiguratorModularProduct,
    BffConfiguratorSaved,
    BffDelivery,
    BffPrice,
    BffProductImage,
    BffProductInputProduct
} from '@common/graphql/sdk';
import { generateImageLinkFromSku, getBffLanguageAndCountry } from '@common/hooks/use-config';
import { unmarshalSKU } from '@common/utils/sku';
import isEqual from 'lodash/isEqual';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { ConfiguratorRecommendation } from '../components/recommendations/recommendations';
import {
    EmersyaPayload,
    EmersyaPayloadNode,
    EmersyaPayloadNodeInstance,
    EmersyaProduct
} from '../components/viewer/viewer-types';
import { WidgetConfiguratorContentRecommendation } from '../configurator-types';
import { DragPayload } from '../widgets/configurator-modular/configurator-modular';
import { ConfiguratorTab, ConfiguratorUI, FilterKey, LayerId } from './types';
import { useShoppingList } from './use-shoppinglist';
import { useViewer } from './use-viewer';
import { calculateFilterCount, createSearchEngineFromColors, searchForColors } from './utils';

export const getAllModularConfiguratorData = async (products: BffProductInputProduct[]) => {
    const { market, language } = getBffLanguageAndCountry();
    const resp: any = await client.getModularConfigurator({
        market,
        language,
        products
    });

    return {
        ...resp.getModularConfigurator,
        trace: resp.trace?.traceId
    };
};

/**
 * Gets the layer name from stings like "change-node-colors-1-21", "change-node-attribute-3"
 * @param layerId
 * @returns
 */
const getLayerName = (layerId: string): string => {
    const match = layerId.match(/\d/);
    return match ? layerId.slice(0, match.index) : layerId;
};

interface ModularConfiguratorStore {
    ui: ConfiguratorUI;

    articleNo: string;

    // Inital Response from the BFF
    initialData?: BffConfiguratorModular;

    // Current Response from the BFF
    activeData?: BffConfiguratorModular;

    // Stack of Emersya Events to undo the last elements
    history: EmersyaPayload[];

    saved: BffConfiguratorSaved[];

    emersyaProducts: EmersyaProduct[];

    presets: {
        state: 'loading' | 'loaded' | 'error';
        currentEmersyaId?: string;
        data?: BffConfiguratorModularPreset[];
        recommendations?: ConfiguratorRecommendation[];
    };

    currentSkus: Record<string, number>;
    price?: BffPrice;
    delivery?: BffConfiguratorDelivery;
    nodes?: EmersyaPayload;
    selectedProduct?: EmersyaPayloadNode;
    trackedProductNodes?: EmersyaPayloadNodeInstance[];
    categoriesWithProducts: BffConfiguratorModularCategoriesWithProducts[];
}

export interface BffConfiguratorModularProductWithNode extends BffConfiguratorModularProduct {
    node: EmersyaPayloadNode;
    configurations: BffConfiguratorConfiguration[];
    selectedProduct?: EmersyaPayloadNode;
}

export interface BffConfiguratorModularCategoriesWithProducts extends BffConfiguratorModularCategories {
    products: BffConfiguratorModularProductWithNode[];
}

const ModularSearchEngineKey = 'all-colors';

interface ModularConfiguratorActions {
    isModularProduct: (sku: string) => boolean;
    initWidget: ({ articleNo }: { articleNo: string }) => void;
    initRecommendations: (widgetRecommendations: WidgetConfiguratorContentRecommendation[]) => Promise<void>;
    hasProducts: () => boolean;

    getActiveProductCategories: () => BffConfiguratorModularCategories[];

    getFilteredColors: () => BffConfiguratorConfigurationColorItem[];

    /**
     * Retun active filter count for the selected filterId
     * @returns number
     */
    getFilterCount: () => number;

    modularConfiguratorAllProductsGet: (emersyaProducts: EmersyaProduct[]) => Promise<void>;

    /**
     * Sets tab in the ui
     * @param tab ConfiguratorTab
     * @returns void
     */
    onSetTab: (tab: ConfiguratorTab) => void;

    updateProductsFromViewer: (payload: EmersyaPayload) => Promise<void>;
    /**
     *  Set the drag overlay data
     */
    updateDragOverlay: (data: DragPayload | null) => void;

    /**
     * Sets the current active preset in the recommedations view
     */
    setPresetEmersyaId: (emersyaId: string) => void;

    /**
     * Sets the blocking error
     */
    togglePresetLoadingError: (error: boolean, message?: string) => void;

    /**
     * Selected slot/product, used for context menu
     */
    setSelectedProductSlot: (data: any) => void;

    /**
     * Tracked product nodes. Registered nodes will report a tracking update (x,y position)
     * This will be used to place the context menu in the correct position
     */
    setTrackedProductNodes: (data: EmersyaPayloadNodeInstance[]) => void;

    /**
     * Triggers the ghosted product nodes (calls the viewer function)
     */
    triggerGhostedProductNodes: () => void;

    /**
     * Gets the productdata via the node localId
     */
    getProductByNodeLocalId: (localId: string | number) => BffConfiguratorModularProductWithNode | undefined;

    /**
     * Gets a list of colors for the current product
     */
    getColorMapForProduct: (
        product?: BffConfiguratorModularProductWithNode
    ) => Map<string, BffConfiguratorConfigurationColorItem>;

    /**
     * Clears all filters
     * @returns void
     */
    onClearFilter: () => void;

    /**
     * set's a filter on the current open filter layer
     * @param key FilterKey
     * @param filter Filter Values
     * @returns
     */
    onSetFilter: (key: FilterKey, filter: string | string[] | undefined) => void;

    pushLayer: (id: LayerId) => void;
    popLayer: () => void;
    setSecondaryLayer: (id: LayerId) => void;
    clearSecondaryLayer: () => void;
    onClearLayer: () => void;
    getCategoryImage: (categoryId: string) => BffProductImage | undefined;
    modularAddToShoppingList: () => Promise<void>;

    /**
     * Open or close the modal
     * @param isOpen boolean
     * @returns
     */
    setIsModalOpen: (isOpen: boolean) => void;

    /**
     * Open or close the compare panel
     * @param isOpen boolean
     * @returns
     */
    setIsCompareOpen: (isOpen: boolean) => void;

    /**
     * Removes Last History Element
     * @returns
     */
    removeHistory: () => void;
    clearHistory: () => void;

    /**
     * Sets the ui to blocked or unblocked
     * @returns
     */
    setIsBlocked: (isBlocked: boolean) => void;

    /**
     * Retuns true if the skus or quantity changed in the nodes
     * @param next EmersyaPayloadNode
     * @returns boolean
     */
    skusOrQuanityChangedInNodes: (next: EmersyaPayloadNode[]) => boolean;
}

export const makeSkuFromNode = (node: EmersyaPayloadNode) =>
    // fromRelation is used for connectors and they do have an invalid furnitureConfiguration
    // @todo: remove this when emersya fixes this
    [node.furnitureArticle, node.fromRelation ? false : node.furnitureConfiguration].filter((el) => !!el).join('|');

export const skusChangedInNodes = (current: EmersyaPayloadNode[], next: EmersyaPayloadNode[]): boolean => {
    const requestedSkus = next
        .filter((node) => node.localId !== 1)
        .map((node) => makeSkuFromNode(node))
        .sort();

    const currentSkus = current
        .filter((node) => node.localId !== 1)
        .map((node) => makeSkuFromNode(node))
        .sort();

    return !isEqual(requestedSkus, currentSkus);
};

export const allColorsIds = 'all-colors';

// @todo: move this into the data
export const ANAGRAM_SKU = 'COMCOLLANAGRAM';

export const getLayerPushFromConfiguration = (product: BffConfiguratorModularProductWithNode): LayerId | undefined => {
    if (!product.configurations || product.configurations.length === 0) {
        return;
    }

    if (product.configurations.length === 1) {
        const configuration = product.configurations[0];
        if (configuration.__typename === 'BffConfiguratorConfigurationColor') {
            return `change-node-colors-${product.node.localId}-0`;
        }
        if (configuration.__typename === 'BffConfiguratorConfigurationAttributes') {
            return `change-node-attribute-${product.node.localId}-0`;
        }
    }

    if (product.configurations.length > 1) {
        return `change-node-overview-${product.node.localId}`;
    }
};

export const useModularConfigurator = create<ModularConfiguratorStore & ModularConfiguratorActions>()(
    immer((set, get) => ({
        // The base article number which identifies the main product
        articleNo: ANAGRAM_SKU,

        ui: {
            searchEngineMap: {},
            isLoading: true,
            isModalOpen: false,
            isFilterOpen: false,
            isCompareOpen: false,
            isBlocked: false,
            tab: 'presets',
            primaryStack: [],
            secondaryStack: [],
            panelAnimation: 'enter',
            dragOverlayPayload: undefined,
            initialized: false
        },

        presets: {
            state: 'loading',
            currentEmersyaId: undefined,
            data: undefined
        },

        saved: [],

        // inital data holds all products and categories
        initialData: undefined,

        // holdas the last request and confiuration options
        activeData: undefined,

        // Last n Emersya Events to undo the last n elements
        history: [],

        // the data fromt he viewer
        emersyaProducts: [],
        categoriesWithProducts: [],
        currentSkus: {},
        selectedProduct: undefined,

        initWidget: (options) => {
            if (options.articleNo === '21076100' || options.articleNo === 'COMCOLLANAGRAM') {
                set((state) => {
                    state.articleNo = 'COMCOLLANAGRAM';
                });
            }
        },

        initRecommendations: async (widgetRecommendations: WidgetConfiguratorContentRecommendation[]) => {
            if (!widgetRecommendations || widgetRecommendations.length === 0) {
                return;
            }

            // Avoid loading presets if we already have them
            const hasPresets = (get().presets.recommendations || []).length > 0;
            if (hasPresets) {
                return;
            }

            const emersyaIds = widgetRecommendations.flatMap((product) =>
                product.products.map((product) => product.id)
            );
            const { language, market } = getBffLanguageAndCountry();
            const { getModularConfiguratorPresets } = await client.getModularConfiguratorPresets({
                market,
                language,
                emersyaIds: emersyaIds
            });

            // @todo: error handling
            if (getModularConfiguratorPresets?.__typename === 'QueryGetModularConfiguratorPresetsSuccess') {
                const data = getModularConfiguratorPresets.data;
                set((state) => {
                    state.presets.data = data as BffConfiguratorModularPreset[];
                    state.presets.recommendations = data.map((preset) => {
                        const widgetRecommendationsSection = widgetRecommendations.find((item) =>
                            item.products.find((product) => product.id.includes(preset.emersyaId))
                        );

                        const item: ConfiguratorRecommendation = {
                            id: preset.emersyaId,
                            name: widgetRecommendationsSection?.name || '',
                            image: preset.image as BffProductImage,
                            price: preset.price as BffPrice,
                            delivery: preset.delivery as BffDelivery
                        };
                        return item;
                    });
                    state.presets.currentEmersyaId = data[0]?.emersyaId;
                });
            }
            set((state) => {
                state.presets.state = 'loaded';
            });
        },
        isModularProduct(sku: string) {
            const { articleNo } = get();
            const { articleNo: articleNoToCheck } = unmarshalSKU(sku);
            return articleNo === articleNoToCheck;
        },
        getCategoryImage(categoryId: string) {
            const { categoriesWithProducts, initialData } = get();
            const category = categoriesWithProducts.find((category) => category.id === categoryId);
            if (category) {
                return category.products.at(-1)?.image as BffProductImage;
            }

            return initialData?.products?.find((product) => product.category === categoryId)?.image as BffProductImage;
        },
        getActiveProductCategories: () => {
            return (
                get().initialData?.categories?.filter((category) => category.quantity && category.quantity > 0) || []
            );
        },
        onClearFilter: () => {
            set((state) => {
                state.ui.filters = undefined;
            });
        },
        onSetTab: (tab) => {
            set((state) => {
                state.ui.tab = tab;
            });
        },
        popLayer: () => {
            set((state) => {
                // remove last element
                state.ui.primaryStack.pop();
                state.ui.panelAnimation = 'exit';

                if (state.ui.primaryStack.length === 0) {
                    state.ui.panelAnimation = 'enter';
                }

                // If the current layer is not a node, setSelectedSlot to null
                const currentLayer = state.ui.primaryStack.at(-1);

                if (!currentLayer?.startsWith('change-node-overview-')) {
                    state.selectedProduct = undefined;
                }

                // always remove secondary stack
                state.ui.secondaryStack = [];
            });
        },
        clearSecondaryLayer: () => {
            set((state) => {
                // remove last element
                state.ui.secondaryStack = [];
            });
        },
        setSecondaryLayer: (id: LayerId) => {
            set((state) => {
                // add last element
                state.ui.secondaryStack.push(id);
            });
        },
        pushLayer: (id: LayerId) => {
            set((state) => {
                // if we are on a detail layer and navigate to another detail layer, we need to remove the last element
                // Otherwise we the history fills up with every click on an element in the viewer
                if ((state.ui.primaryStack.at(-1) ?? '').includes('change-node-overview-')) {
                    state.ui.primaryStack.pop();
                    state.ui.panelAnimation = 'fadeOutIn';
                } else {
                    state.ui.panelAnimation = 'enter';
                }
                state.ui.primaryStack.push(id);
            });
        },
        onClearLayer: () => {
            set((state) => {
                state.ui.primaryStack = [];
            });
        },
        updateDragOverlay: (data: any) => {
            set((state) => {
                state.ui.dragOverlayPayload = data;
            });
        },
        hasProducts: () => {
            const { nodes } = get();
            return !!nodes;
        },
        onSetFilter: (key, filter) => {
            set((state) => {
                if (!state.ui.filters) {
                    state.ui.filters = {};
                }
                state.ui.filters[key] = filter as any;
            });
        },
        getFilterCount: (): number => calculateFilterCount(get().ui?.filters),
        getFilteredColors: (): BffConfiguratorConfigurationColorItem[] => {
            const { ui, initialData } = get();

            if (!ui || !ui.filters || !ui.searchEngineMap || !ui.searchEngineMap[ModularSearchEngineKey]) {
                return initialData?.colors || [];
            }

            return searchForColors(ui.searchEngineMap[ModularSearchEngineKey], ui.filters);
        },
        removeHistory() {
            const currentHistory = get().history;
            set((state) => {
                if (currentHistory.length < 2) {
                    return;
                }
                // remove everything -2 of the histroy array;
                const history = currentHistory.slice(0, -2);
                state.history = history;
            });
        },
        clearHistory: () => {
            set((state) => {
                state.history = [];
            });
        },
        /**
         * This function is called if the scene in the viewer changes
         * It updates the products and categories in the configurator by counting the number of similar products
         * It identifies the last added product from the bff and tries to select the same color for the category images
         */
        updateProductsFromViewer: async (data: EmersyaPayload) => {
            set((state) => {
                state.ui.isLoading = true;
            });
            const skuQuantity: Record<string, number> = {};

            for (const node of data.allNodes) {
                if (!node.furnitureArticle) {
                    continue;
                }
                const sku = makeSkuFromNode(node);
                if (!sku) {
                    continue;
                }
                if (skuQuantity[sku]) {
                    skuQuantity[sku] += 1;
                } else {
                    skuQuantity[sku] = 1;
                }
            }
            const requestProducts = Object.keys(skuQuantity).map((sku) => {
                return {
                    sku,
                    quantity: skuQuantity[sku]
                };
            });

            // happens when you reset the viewer with undo
            if (requestProducts.length === 0) {
                set((state) => {
                    state.currentSkus = {};
                    state.price = undefined;
                    state.delivery = undefined;
                    state.activeData = undefined;
                    state.categoriesWithProducts = [];
                    state.nodes = undefined;
                    state.ui.isLoading = false;
                });
                return;
            }

            if (JSON.stringify(requestProducts) === JSON.stringify(get().currentSkus)) {
                set((state) => {
                    state.nodes = data;
                });

                return;
            }

            let resp: BffConfiguratorModular | undefined;
            try {
                // abort running operions before
                abortRunningOperation('getModularConfigurator');

                const rdata = await getAllModularConfiguratorData(requestProducts);
                if (rdata?.__typename === 'QueryGetModularConfiguratorSuccess') {
                    resp = rdata.data as any as BffConfiguratorModular;
                } else {
                    set((state) => {
                        state.ui.blockingError = {
                            trace: (rdata as any).trace,
                            message: rdata?.message || 'error'
                        };
                    });
                }
            } catch (error: any) {
                if (error instanceof DOMException && error.name === 'AbortError') {
                    return;
                }
                set((state) => {
                    state.ui.blockingError = {
                        message: error.message
                    };
                });
            }

            if (!resp) {
                return;
            }

            // Add Element to History if the Products Change
            const { history } = get();
            set((state) => {
                // Check if the last history entry is exactly the same as the incoming data
                // If so, we don't need to add it to the history

                const lastHistoryEntry = history.at(-1);
                if (lastHistoryEntry && isEqual(lastHistoryEntry, data)) {
                    return;
                }

                const newHistory = [...history, data];
                if (history.length === 0) {
                    // this is fromt the emerya sample to reset the viewer with no element
                    newHistory.unshift({
                        allNodes: [],
                        root: {
                            SKU: data.root.SKU,
                            children: [],
                            localId: 1,
                            name: 'none'
                        }
                    } as any);
                }
                state.history = newHistory;
            });

            const nodes = (data.allNodes || []).filter((node) => node.localIds.length >= 2);
            const initialData = get().initialData;
            let allProducts = initialData?.products || [];
            const allCategories = initialData?.categories || [];
            const categoriesMap: Record<string, any> = {};
            const lastAddedProduct = resp.products?.at(-1);

            // Collect unique color configurations for each product category
            const builderCategoryMaterials: {
                category: string;
                sku: string;
                config: BffConfiguratorConfigurationColor;
            }[] = [];

            for (const product of resp.products || []) {
                if (!product.category) continue;

                const colorConfigurations =
                    product.configurations?.filter(
                        (config): config is BffConfiguratorConfigurationColor =>
                            config.__typename === 'BffConfiguratorConfigurationColor'
                    ) || [];

                for (const colorConfig of colorConfigurations) {
                    builderCategoryMaterials.push({
                        category: product.category,
                        sku: product.sku,
                        config: colorConfig
                    });
                }
            }

            //  Apply materials to all products
            if (builderCategoryMaterials.length > 0) {
                allProducts = allProducts.map((product) => {
                    // First try to find a matching material for the product in the builders/viewers existing configurations
                    let categoryMaterial = builderCategoryMaterials.find(
                        (config) =>
                            config.category === product.category &&
                            unmarshalSKU(config.sku).articleNo === unmarshalSKU(product.sku).articleNo
                    );

                    // If no matching configuration found, try to use the last added product's configuration
                    if (!categoryMaterial && lastAddedProduct) {
                        const colorConfiguration = lastAddedProduct.configurations?.find(
                            (config): config is BffConfiguratorConfigurationColor =>
                                config.__typename === 'BffConfiguratorConfigurationColor'
                        );

                        if (colorConfiguration) {
                            const selectedColor = colorConfiguration.colors?.find(
                                (color) => color.sku === lastAddedProduct.sku
                            );

                            let selectedProductColor = product.configurations
                                ?.find(
                                    (config): config is BffConfiguratorConfigurationColor =>
                                        config.__typename === 'BffConfiguratorConfigurationColor'
                                )
                                ?.colors?.find((c) => c.id === selectedColor?.id) as any;

                            if (unmarshalSKU(lastAddedProduct.sku).articleNo === unmarshalSKU(product.sku).articleNo) {
                                selectedProductColor = selectedColor;
                            }

                            if (!selectedProductColor) return product;

                            categoryMaterial = selectedProductColor;
                        }
                    }

                    if (!categoryMaterial) return product;

                    return {
                        ...product,
                        sku: categoryMaterial.sku,
                        image: {
                            src: generateImageLinkFromSku(categoryMaterial.sku, true)
                        }
                    };
                });
            }

            // Map product nodes into categories for display
            for (const node of nodes) {
                const product = allProducts.find(
                    (product) => unmarshalSKU(product.sku).articleNo === node.furnitureArticle
                );

                if (product && product.category) {
                    const configurations =
                        resp.products?.find((p) => p.sku === makeSkuFromNode(node))?.configurations || [];
                    const pNode = {
                        ...product,
                        configurations,
                        node
                    };

                    if (categoriesMap[product.category]) {
                        categoriesMap[product.category].push(pNode);
                    } else {
                        categoriesMap[product.category] = [pNode];
                    }
                }
            }

            const categories = Object.keys(categoriesMap).map((key) => {
                return {
                    ...allCategories?.find((category) => category.id === key),
                    products: categoriesMap[key]
                };
            }) as BffConfiguratorModularCategoriesWithProducts[];

            set((state) => {
                state.ui.isLoading = false;
                state.currentSkus = skuQuantity;
                state.price = resp?.price as BffPrice;
                state.delivery = resp?.delivery as BffConfiguratorDelivery;
                state.activeData = resp as BffConfiguratorModular;
                state.categoriesWithProducts = categories;
                state.nodes = data;
                if (initialData) {
                    state.initialData = {
                        ...initialData,
                        products: allProducts
                    };
                }

                state.ui.blockingError = undefined;
            });
        },
        setPresetEmersyaId: (id: string) => {
            set((state) => {
                state.presets.currentEmersyaId = id;
            });
        },
        togglePresetLoadingError: (error: boolean, message?: string) => {
            set((state) => {
                state.ui.blockingError =
                    error && message
                        ? {
                              message: message
                          }
                        : undefined;
            });
        },
        setSelectedProductSlot: (data: any | null) => {
            const { selectedProduct, pushLayer, popLayer, getProductByNodeLocalId } = get();

            // de-select item
            if (data === null) {
                set((state) => {
                    state.ui.primaryStack = [];
                    state.selectedProduct = undefined;
                    state.ui.isCompareOpen = false;
                });

                return;
            }

            const currentLocalId = selectedProduct?.localId;

            // If the selected product is the same we must only set state.selectedProduct.
            // This way we assure that things like the context menu are aware that
            // they need to be shown again if the user clicked on the same product
            // again and the context menu is not shown atm
            if (data.product?.localId === currentLocalId) {
                set((state) => {
                    state.selectedProduct = data?.product as EmersyaPayloadNode;
                });
                return;
            }

            const productFromNode = getProductByNodeLocalId(data?.product?.localId);
            const nextLayer = productFromNode ? getLayerPushFromConfiguration(productFromNode) : undefined;
            const currentLayer = get().ui.primaryStack.at(-1) ?? '';
            const isNewLayer = nextLayer && getLayerName(nextLayer) !== getLayerName(currentLayer);

            // Remove last layer if
            // * the selected product is not the same
            // * if the next layer is a new layer type
            if (currentLocalId && data.product?.localId !== currentLocalId && isNewLayer) {
                popLayer();
            }

            set((state) => {
                state.selectedProduct = data?.product as EmersyaPayloadNode;
                state.ui.isCompareOpen = false;
            });

            if (!nextLayer) {
                return;
            }

            // If the nextLayer is the same type as the current, replace the current layer
            if (!isNewLayer) {
                set((state) => {
                    state.ui.primaryStack[state.ui.primaryStack.length - 1] = nextLayer;
                });

                return;
            }

            pushLayer(nextLayer);
        },
        getProductByNodeLocalId: (localId: string | number): BffConfiguratorModularProductWithNode | undefined => {
            const { nodes, activeData } = get();
            if (!nodes || !activeData) {
                return;
            }
            const node = nodes?.allNodes.find((node) => `${node.localId}` === `${localId}`);
            if (!node) {
                return;
            }
            const sku = makeSkuFromNode(node);
            const p = activeData.products?.find((product) => product.sku === sku);
            if (!p) {
                return;
            }
            return {
                node,
                ...p
            } as BffConfiguratorModularProductWithNode;
        },
        getColorMapForProduct: (product?: BffConfiguratorModularProductWithNode) => {
            if (!product) {
                const { activeData } = get();

                const productsWithColorConfigurations = activeData?.products?.filter(
                    (product) =>
                        product.configurations?.some(
                            (config) => config.__typename === 'BffConfiguratorConfigurationColor'
                        ) || false
                );

                if (!productsWithColorConfigurations) {
                    return new Map();
                }

                // All colors for all products, flatmapped
                const allColorsFlatMap = productsWithColorConfigurations.flatMap((product) => {
                    return (product.configurations || []).flatMap((config) => {
                        if (config.__typename === 'BffConfiguratorConfigurationColor') {
                            return config?.colors || [];
                        }
                        return [];
                    });
                });

                // Filter through the allColorsFlatMap
                const commonColorsFlatMap = allColorsFlatMap.filter((color) => {
                    // Check if every product that is BffConfiguratorConfigurationColor has this color
                    return productsWithColorConfigurations.every((product) => {
                        // If not, filter out the color
                        return product?.configurations?.some(
                            (config) =>
                                config.__typename === 'BffConfiguratorConfigurationColor' &&
                                config?.colors?.some((c) => c.id === color.id)
                        );
                    });
                });

                // Shaped as a map
                return new Map(commonColorsFlatMap.map((color) => [color.id, color]));
            }

            const colorMap = new Map(
                product?.configurations
                    .flatMap((config) => {
                        if (config.__typename === 'BffConfiguratorConfigurationColor') {
                            return config?.colors || [];
                        }
                        return [];
                    })
                    .map((color) => [color.id, color])
            );

            return colorMap;
        },
        setTrackedProductNodes: (data: EmersyaPayloadNodeInstance[]) => {
            // this should only update when the value changes
            if (JSON.stringify(data) === JSON.stringify(get().trackedProductNodes)) {
                return;
            }

            set((state) => {
                state.trackedProductNodes = data;
            });
        },

        triggerGhostedProductNodes: () => {
            const { nodes, selectedProduct } = get();

            if (!nodes) {
                return;
            }

            const allLocalIds = nodes.allNodes.flatMap((node) => node.localIds);

            const n = allLocalIds.map((id) => ({
                localId: id,
                renderAsGhost: selectedProduct ? !selectedProduct.localIds.includes(id) : false
            }));

            useViewer.getState().setProductNodesToGhostRenderingMode(n);
        },

        modularAddToShoppingList: async () => {
            const { articleNo } = get();
            const modularCreateSharedConfiguration = useViewer.getState().modularCreateSharedConfiguration;
            const updateShoppingList = useShoppingList.getState().updateShoppingList;
            const emersyaSharedId = await modularCreateSharedConfiguration(articleNo);
            await updateShoppingList([{ sku: `${articleNo}|${emersyaSharedId}` }], [articleNo]);
        },

        modularConfiguratorAllProductsGet: async (emersyaProducts: EmersyaProduct[]) => {
            const isNotInitalized = !get().ui.initialized;

            if (isNotInitalized) {
                set((state) => {
                    state.ui.isLoading = true;
                    state.emersyaProducts = emersyaProducts;
                });

                const requestProducts = emersyaProducts
                    .filter((el) => el.article.length > 5)
                    .map((el) => {
                        return {
                            sku: [el.article, el.defaultConfiguration].filter((el) => !!el).join('|'),
                            quantity: 0
                        };
                    });

                try {
                    const rdata = await getAllModularConfiguratorData(requestProducts);
                    if (rdata?.__typename === 'QueryGetModularConfiguratorSuccess') {
                        set((state) => {
                            state.ui.isLoading = false;
                            state.ui.initialized = true;
                            state.initialData = rdata.data as BffConfiguratorModular;

                            // Create search engine
                            const colors = rdata.data?.colors || ([] as any);
                            const searchEngine = createSearchEngineFromColors(colors, 'en');
                            if (!state.ui.searchEngineMap) {
                                state.ui.searchEngineMap = {};
                            }
                            state.ui.searchEngineMap[ModularSearchEngineKey] = searchEngine;
                        });
                    } else {
                        set((state) => {
                            state.ui.blockingError = {
                                trace: (rdata as any).trace,
                                message: rdata?.message || 'error'
                            };
                        });
                    }
                } catch (error: any) {
                    if (error instanceof DOMException && error.name === 'AbortError') {
                        return;
                    }

                    set((state) => {
                        state.ui.blockingError = {
                            message: error.message
                        };
                    });
                }
            } else {
                set((state) => {
                    state.emersyaProducts = emersyaProducts;
                });
            }
        },
        setIsModalOpen: (isOpen: boolean) => {
            set((state) => {
                state.ui.isModalOpen = isOpen;
            });
        },
        setIsCompareOpen: (isOpen: boolean) => {
            set((state) => {
                state.ui.isCompareOpen = isOpen;
            });
        },
        setIsBlocked: (isBlocked: boolean) => {
            set((state) => {
                state.ui.isBlocked = isBlocked;
            });
        },
        skusOrQuanityChangedInNodes: (next: EmersyaPayloadNode[]): boolean => {
            const current = get().nodes?.allNodes || [];
            return skusChangedInNodes(current, next);
        }
    }))
);
