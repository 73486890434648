import { css, cx } from '@linaria/core';
import type { AriaToastProps, AriaToastRegionProps } from '@react-aria/toast';
import { useToast, useToastRegion } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { useToastState } from '@react-stately/toast';
import { useRef } from 'react';
import { Link, LinkProps } from 'react-aria-components';

interface ToastProps<T> extends AriaToastProps<T> {
    state: ToastState<T>;
}

const styles = {
    region: css`
        position: fixed;
        z-index: 10000;
        bottom: 99px;
        left: 50%;
        transform: translate(-50%, 0);
    `,
    toast: css`
        display: flex;
        align-items: center;
        gap: 8px;
        background: #fff;
        padding: 12px 16px 13px;
        border-radius: 8px;
        box-shadow: 0 1.867px 3.734px 0 rgba(0 0 0 / 15%);
        color: #000;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 15px */
    `,
    icon: css`
        width: 15px;
        height: 15px;
    `,
    link: css`
        color: inherit;
        text-decoration: underline;
        text-underline-offset: 2px;
    `
};

export const Toast = <T extends React.ReactNode>({ state, ...props }: ToastProps<T>) => {
    const ref = useRef(null);
    const { toastProps, titleProps } = useToast(props, state, ref);

    return (
        <div {...toastProps} ref={ref} className={cx(styles.toast)}>
            <svg fill="none" viewBox="0 0 15 15" className={cx(styles.icon)}>
                <g filter="url(#a)">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#EE5757" />
                </g>
                <path stroke="#fff" strokeWidth="1.2" d="m4.16553 7.68516 2.38095 2.31482 4.28572-4.16667" />
                <defs>
                    <filter
                        id="a"
                        width="15"
                        height="17"
                        x="0"
                        y="-1"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_60_4240" />
                        <feColorMatrix
                            in="SourceAlpha"
                            result="hardAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="-1" />
                        <feGaussianBlur stdDeviation=".5" />
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                        <feBlend in2="effect1_innerShadow_60_4240" result="effect2_innerShadow_60_4240" />
                    </filter>
                </defs>
            </svg>
            <div {...titleProps}>{props.toast.content}</div>
        </div>
    );
};

interface ToastRegionProps<T> extends AriaToastRegionProps {
    state: ToastState<T>;
}

function ToastRegion<T extends React.ReactNode>({ state, ...props }: ToastRegionProps<T>) {
    const ref = useRef(null);
    const { regionProps } = useToastRegion(props, state, ref);

    return (
        <div {...regionProps} ref={ref} className={cx(styles.region)}>
            {state.visibleToasts.map((toast) => (
                <Toast key={toast.key} toast={toast} state={state} />
            ))}
        </div>
    );
}

export const ToastLink = (props: LinkProps) => <Link {...props} className={cx(styles.link)} />;

export const ToastProvider = ({
    children,
    ...props
}: {
    children: (state: ToastState<React.ReactNode>) => React.ReactNode;
}) => {
    const state = useToastState({
        maxVisibleToasts: 1
    }) as ToastState<React.ReactNode>;
    return (
        <>
            {children(state)}
            {state.visibleToasts.length > 0 && <ToastRegion<React.ReactNode> {...props} state={state} />}
        </>
    );
};
