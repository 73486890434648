import { BffConfiguratorConfigurationColorItem, BffConfiguratorConfigurationColorMaterial } from '@common/graphql/sdk';
import { useMedia } from '@common/hooks/use-media';
import { Tick } from '@common/icons';
import { Button } from '@common/primitives/button/button';
import { colors } from '@common/styles/colors';
import { media } from '@common/styles/media';
import { PANEL_WIDTH } from '@components/configurator/components/panel/constants';
import { ROW_WIDTH } from '@components/material-comparison/constants';
import { AttributeKey } from '@components/material-comparison/material-comparison-types';
import translations from '@i18n/variables';
import { css, cx } from '@linaria/core';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { Label, Tab, TabList, TabPanel, Tabs } from 'react-aria-components';

import { TableCell, TableCellEmptyParagraph, TableCellTextParagraph } from './table-cell';
import { TableColumnGroup, TableColumnHeader } from './table-column';
import { TableEmptyContent } from './table-empty-content';
import { TableHead } from './table-head';
import { TableRow } from './table-row';
import { TableRows } from './table-rows';

const styles = {
    table: css`
        width: 100%;
    `,
    tableBody: css`
        padding-left: 14px;
        padding-right: 14px;
        display: block;
        position: relative;

        tr {
            display: flex;
            flex-direction: row;
            width: calc(100dvw - 14px * 2);

            ${media.md} {
                width: fit-content;
            }
        }
    `,
    tableThreeRows: css`
        tr {
            ${media.md} {
                width: calc(3 * ${ROW_WIDTH + 14}px);
                padding-left: calc((${PANEL_WIDTH.XL}px - (3 * ${ROW_WIDTH + 36}px)) / 2);
                padding-right: calc((${PANEL_WIDTH.XL}px - (3 * ${ROW_WIDTH + 36}px)) / 2);
                box-sizing: content-box;
            }
        }
    `,
    tableFourRows: css`
        tr {
            ${media.md} {
                width: calc(4 * ${ROW_WIDTH + 14}px);
                padding-left: calc((${PANEL_WIDTH.XL}px - (4 * ${ROW_WIDTH + 36}px)) / 2);
                padding-right: calc((${PANEL_WIDTH.XL}px - (4 * ${ROW_WIDTH + 36}px)) / 2);
                box-sizing: content-box;
            }
        }
    `,
    descriptionCell: css`
        padding-bottom: 0;
    `,
    descriptionRowButton: css`
        padding: 0 0 54px;
    `,
    descriptionRowButtonCell: css`
        padding-top: 0;
    `,
    tabs: css`
        width: 100%;
    `,
    tabList: css`
        display: flex;
        justify-content: center;
        padding-top: 22px;
        width: 100%;
    `,
    tabListItem: css`
        flex: 1;
        overflow: hidden;
        border-bottom: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        padding: 15px 21px;
        margin-top: 32px;
        display: flex;
        color: ${colors.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 122%; /* 17.08px */
        text-align: left;
        margin-left: auto;
        margin-right: auto;

        &[data-selected] {
            color: ${colors.coral2};
            border-bottom-color: transparent;
        }

        &[data-focus-visible] {
            outline: 1.5px solid ${colors.coral2};
        }

        &:last-child {
            border-right: none;
            padding-right: 0;
        }

        &:first-child {
            padding-left: 0;
        }

        &::first-letter {
            text-transform: uppercase;
        }
    `,
    tabListItemText: css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    `,
    tabPanel: css`
        padding-top: 22px;

        &::first-line {
            font-weight: 600;
        }
    `,
    buttonRow: css`
        display: flex;
        gap: 14px;
        padding: 22px 0;
    `
};

export interface MaterialComparisonTableProps {
    currencyCode?: string;
    materials?: BffConfiguratorConfigurationColorMaterial[];
    selectedColors: BffConfiguratorConfigurationColorItem[];
    showDifferencesOnly: boolean;
    onClose: () => void;
    onRemoveColor: (key: string) => void;
    onApplyColor: (color: BffConfiguratorConfigurationColorItem) => void;
}

export const MaterialComparisonTable = ({
    currencyCode = 'EUR',
    materials,
    selectedColors,
    showDifferencesOnly,
    onRemoveColor,
    onClose,
    onApplyColor
}: MaterialComparisonTableProps) => {
    const { md } = useMedia();

    const [isFabricApplied, setIsFabricApplied] = useState<string>();
    const [pinnedRows, setPinnedRows] = useState<AttributeKey[]>([]);

    // Lingui hook used to track translations
    const { _ } = useLingui();

    const selectedColorsWithAttributeKeys = selectedColors.map((color) => {
        const material = materials?.find((m) => m.id === color.material);
        const mergedAttributes = [...(color?.attributes ?? []), ...(material?.attributes ?? [])];
        const attributes = mergedAttributes;

        return {
            ...color,
            description: material?.text,
            attributes
        };
    });

    // Paging logic
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4; // Always show 4 items per page
    const totalPages = Math.ceil((selectedColorsWithAttributeKeys.length - itemsPerPage) / 1) + 2; // Add one extra page for the empty columns

    const startIndex = currentPage;
    const endIndex = startIndex + itemsPerPage;
    let pagedColors = selectedColorsWithAttributeKeys.slice(startIndex, endIndex);

    // Ensure 4 items by moving up one item in the array
    if (pagedColors.length < itemsPerPage && selectedColorsWithAttributeKeys.length > itemsPerPage) {
        pagedColors = selectedColorsWithAttributeKeys.slice(startIndex, startIndex + itemsPerPage);
    }

    // Show the empty if we've reached the end of the array or on the extra page
    const isLastPage = currentPage === totalPages - 1;
    const showEmpty = md && (isLastPage || pagedColors.length < itemsPerPage);

    const handleNextPage = () => setCurrentPage((page) => Math.min(page + 1, totalPages - 1));
    const handlePreviousPage = () => setCurrentPage((page) => Math.max(page - 1, 0));

    const handleApplyFabric = (color: BffConfiguratorConfigurationColorItem) => {
        setIsFabricApplied(color.id);
        onApplyColor(color);
    };

    const renderApplyFabricButton = (color: BffConfiguratorConfigurationColorItem) => {
        const isApplied = isFabricApplied === color.id;

        return (
            <Button
                isDisabled={isApplied}
                onPress={() => handleApplyFabric(color)}
                variant={isApplied ? 'basic' : 'secondary'}
                rounded
            >
                <Trans>Apply material</Trans>
                {isApplied && <Tick color={colors.coral2} />}
            </Button>
        );
    };

    const renderDescription = () => {
        if (!md)
            return (
                <>
                    <tr>
                        <Tabs className={styles.tabs}>
                            <TabList className={styles.tabList}>
                                {pagedColors.map((color) => {
                                    const materialName = materials?.find((m) => m.id === color.material)?.name;
                                    const tabTitle = `${color.title}, ${materialName}`;

                                    return (
                                        <Tab key={color.id} id={color.id} className={styles.tabListItem}>
                                            <span className={styles.tabListItemText}>{tabTitle}</span>
                                        </Tab>
                                    );
                                })}
                            </TabList>

                            {pagedColors.map((color) => {
                                return (
                                    <TabPanel id={color.id} key={color.id} className={styles.tabPanel}>
                                        <TableCellTextParagraph>{color.description}</TableCellTextParagraph>
                                    </TabPanel>
                                );
                            })}
                        </Tabs>
                    </tr>

                    <tr className={styles.buttonRow}>
                        {pagedColors.map((color) => {
                            const key = `${color.id}_desc`;

                            return <td key={key}>{renderApplyFabricButton(color)}</td>;
                        })}
                    </tr>
                </>
            );

        return (
            <>
                <TableColumnHeader>
                    <TableColumnGroup>
                        <Label id={'description-label'}>{_(translations.description)}</Label>
                    </TableColumnGroup>
                </TableColumnHeader>
                <TableRow>
                    {pagedColors.map((color) => {
                        const key = `${color.id}_desc`;

                        return (
                            <TableCell key={key} className={styles.descriptionCell} bordered={false}>
                                <TableCellTextParagraph>{color.description}</TableCellTextParagraph>
                            </TableCell>
                        );
                    })}

                    {showEmpty && (
                        <TableCell bordered={false} className={styles.descriptionCell}>
                            <TableCellEmptyParagraph>
                                <TableEmptyContent width="75%" />
                                <TableEmptyContent width="50%" />
                                <TableEmptyContent width="36%" />
                                <TableEmptyContent width="70%" />
                                <TableEmptyContent width="50%" />
                                <TableEmptyContent width="80%" />
                            </TableCellEmptyParagraph>
                        </TableCell>
                    )}
                </TableRow>
                <TableRow className={styles.descriptionRowButton}>
                    {pagedColors.map((color) => {
                        const key = `${color.id}_desc`;
                        return (
                            <TableCell className={styles.descriptionRowButtonCell} bordered={false} key={key}>
                                {renderApplyFabricButton(color)}
                            </TableCell>
                        );
                    })}

                    {showEmpty && <TableCell className={styles.descriptionRowButtonCell} bordered={false} />}
                </TableRow>
            </>
        );
    };

    const renderTableBody = () => {
        return (
            <TableRows
                keys={[
                    AttributeKey.EasyToClean,
                    AttributeKey.LowMaintenance,
                    AttributeKey.DirtResistance,
                    AttributeKey.HairResistance,
                    AttributeKey.FastnessToRubbing,
                    AttributeKey.AbrasionResistance,
                    AttributeKey.Pilling,
                    AttributeKey.FastnessToLight,
                    AttributeKey.TemperatureBehaviour,
                    AttributeKey.PricingGroup,
                    AttributeKey.Naturalness,
                    AttributeKey.AreaOfUse,
                    AttributeKey.PlaceOfOrigin,
                    AttributeKey.Vegan
                ].filter((attribute) => !pinnedRows.includes(attribute))}
                colors={pagedColors}
                currencyCode={currencyCode}
                showDifferencesOnly={showDifferencesOnly}
                showEmpty={showEmpty}
                pinnedRows={pinnedRows}
                onPinRow={(attribute) => {
                    if (pinnedRows.includes(attribute)) {
                        setPinnedRows((rows) => rows.filter((row) => row !== attribute));
                    } else {
                        setPinnedRows((rows) => [...rows, attribute]);
                    }
                }}
            />
        );
    };

    return (
        <table className={styles.table}>
            <caption>
                <VisuallyHidden>
                    <Trans>Compare Fabrics</Trans>
                </VisuallyHidden>
            </caption>
            <TableHead
                onClose={onClose}
                currentPage={currentPage}
                onChangePage={setCurrentPage}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                colors={pagedColors}
                materials={materials}
                totalPages={totalPages}
                pinnedRows={pinnedRows}
                isRemoveButtonShown={selectedColors.length > 2}
                onRemoveColor={onRemoveColor}
                showEmpty={showEmpty}
                className={cx(
                    md && selectedColors.length <= 2 && styles.tableThreeRows,
                    md && selectedColors.length >= 3 && styles.tableFourRows
                )}
            >
                <TableRows
                    keys={pinnedRows}
                    colors={pagedColors}
                    currencyCode={currencyCode}
                    showDifferencesOnly={showDifferencesOnly}
                    showEmpty={showEmpty}
                    pinnedRows={pinnedRows}
                    onPinRow={(attribute) => {
                        if (pinnedRows.includes(attribute)) {
                            setPinnedRows((rows) => rows.filter((row) => row !== attribute));
                        } else {
                            setPinnedRows((rows) => [...rows, attribute]);
                        }
                    }}
                />
            </TableHead>
            <tbody
                className={cx(
                    styles.tableBody,
                    md && selectedColors.length <= 2 && styles.tableThreeRows,
                    md && selectedColors.length >= 3 && styles.tableFourRows
                )}
            >
                {renderTableBody()}
                {renderDescription()}
            </tbody>
        </table>
    );
};
